import { RouteConfig } from 'vue-router';

const Shaichajiance = () => import('./views/shaichajiance.vue');
const CepingEdit = () => import('./views/ceping-edit.vue');
const CepingReport = () => import('./views/ceping-report.vue');

const routers: RouteConfig[] = [

    {
        path: 'shaichajiance',
        component: Shaichajiance,
    },
    {
        path: 'ceping-edit',
        component: CepingEdit,
    },
    {
        path: 'ceping-report',
        component: CepingReport,
    },
    {
        path: '',
        redirect: 'Shaichajiance'
    },

]
export default routers;
