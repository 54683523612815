import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
const Login = () => import('./views/login/login.vue');
const Main = () => import('./views/main/main.vue');
const Agreement = () => import('./views/agreement/agreement.vue');
const Privacy = () => import('./views/privacy/privacy.vue');
import MainRouterChildren from "./views/main/main.router";
Vue.use(Router);
/**
 * @description 通过获得localstorage来检测是否有token
 */
const checkLoggedIn = (): boolean => {
  let ifLoggedIn: boolean = false;
  const token: string | null = sessionStorage.getItem('token');
  const user: any = JSON.parse(sessionStorage.getItem('user') as any);
  if (token && typeof (token) === 'string' && user && user.doctor_id) {
    ifLoggedIn = true;
    store.commit("updateToken", token);
    store.commit("updateUser", user);
  }
  return ifLoggedIn;
};
const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: Agreement

    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy

    },
    {
      path: '/main',
      name: 'main',
      component: Main,
      children: MainRouterChildren,
      beforeEnter: (to, from, next) => {
        const ifLoggedIn = checkLoggedIn();
        if (ifLoggedIn) {
          next();
        } else {
          next('/login');
        }
      }
    },

  ],
});

export default router;
