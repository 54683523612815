import { RouteConfig } from 'vue-router';

const Yichang = () => import('../yichang/yichang.vue');
const My = () => import('../my/my.vue');
const Workstation = () => import('../workstation/workstation.vue');
const Report = () => import('../report/report.vue');
const Statistics = () => import('../statistics/statistics.vue');

import YichangRouters from "../yichang/yichang.router";
import MyRouters from "../my/my.router";
import WorkstationRouters from "../workstation/workstation.router";
import ReportRouters from "../report/report.router";
import StatisticsRouters from "../statistics/statistics.router";


const routers: RouteConfig[] = [
    {
        path: 'yichang',
        component: Yichang,
        children: YichangRouters
    },
    {
        path: 'report',
        component: Report,
        children: ReportRouters
    },
    {
        path: 'my',
        component: My,
        children: MyRouters
    },
    {
        path: 'workstation',
        component: Workstation,
        children: WorkstationRouters
    },
    {
        path: 'statistics',
        component: Statistics,
        children: StatisticsRouters
    },
    {
        path: '',
        redirect: 'Yichang'
    },
]
export default routers;
