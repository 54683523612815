const partConfiguration: any = {
    项目名称: "宜心健康客户管理平台（医生端）",
    name: "fqxk",// 项目名称，用来项目部分功能的区分。
    ifShowBingshiziliao: true, // 患者信息页面是否显示病史资料
    agreePage: 'fqxk',// 知情同意书页面
    leftNav: [
        {
            name: '客户',
            icon: '/workstation/客户1.png',
            activeIcon: '/workstation/客户2.png',
            path: '/main/workstation/patient-file',
            activePath: '/main/workstation/patient'

        },
        {
            name: '预警',
            icon: '/workstation/预警1.png',
            activeIcon: '/workstation/预警2.png',
            path: '/main/yichang/yichang-list',
            activePath: '/main/yichang/yichang-list',

        },
        {
            name: '测评',
            icon: '/workstation/评估1.png',
            activeIcon: '/workstation/评估2.png',
            path: '/main/report/shaichajiance',
            activePath: '/main/report',
        },
        {
            name: '统计',
            icon: '/workstation/统计1.png',
            activeIcon: '/workstation/统计2.png',
            path: '/main/statistics/folder',
            activePath: '/main/statistics',
        },
    ],
    jiances: [// 指标监测量表选项
        {
            name: "心脏无忧筛查",
            label: "心脏无忧筛查",
        },
        // {
        //     name: "中医体质测评",
        //     label: "中医体质测评",
        // },
    ],
    cepings: [// 健康测评量表,因为后端接口不返回心脏无忧量表，如果有心脏无忧需要在这里配置
        {
            名称: "心脏无忧筛查",
            template_id: "",
        }
    ],
    tijians: [// 体检报告量表
        {
            label: "健康体检设备",
            isOpen: true,
            children: [
                {
                    parent: "健康体检设备",
                    label: "InBody人体成分分析仪",
                },
                {
                    parent: "健康体检设备",
                    label: "嘉乐健康一体机",
                },
                {
                    parent: "健康体检设备",
                    label: "乐普健康随访包",
                },
                // {
                //     parent: "健康体检设备",
                //     label: "云联华康健康随访包",
                // },
                {
                    parent: "健康体检设备",
                    label: "思多科超声仪",
                }
            ],
        },
    ],
    liangbiaoTab: [// 随访数据的量表tab
        {
            name: '随访数据',
            label: '随访数据'
        },
    ],
    danganNavs: [
        {
            label: "诊疗记录",
            value: "诊疗记录",
        },
        {
            label: "检验报告",
            value: "检验报告",
        },
        {
            label: "检查报告",
            value: "检查报告",
        },
        {
            label: "健康设备",
            value: "健康设备",
        },
        {
            label: "健康测评",
            value: "健康测评",
        },
        {
            label: "评估报告",
            value: "评估报告",
        },
        {
            label: "干预方案",
            value: "干预方案",
        },
        {
            label: "居家监测",
            value: "居家监测",
            children: [
                {
                    label: "血糖",
                    value: "血糖",
                },
                {
                    label: "血压",
                    value: "血压",
                },
                {
                    label: "心率",
                    value: "心率",
                },
                {
                    label: "运动",
                    value: "运动",
                },
                {
                    label: "体重",
                    value: "体重",
                },
                {
                    label: "血氧",
                    value: "血氧饱和度",
                },
                {
                    label: "体温",
                    value: "体温",
                },
            ]
        },
        {
            label: "随访管理",
            value: "随访管理",
        },
        // {
        //     label: "医嘱信息",
        //     value: "医嘱信息",
        // },
    ]
};
/**
 * @description 根据环境配置api
 */
const option = (): any => {
    let dsObj = {};
    if (process.env.NODE_ENV === "development") {
        window.API_ROOT = "https://fqxk-test.i-tcm.cn/";
        // window.API_ROOT = "https://fqxk.i-tcm.cn/";
        // window.API_ROOT = "http://10.11.30.10:9010/";
        document.title = "宜心健康客户管理平台（医生端）";
        dsObj = {
            项目名称: "宜心健康客户管理平台（医生端）",
            登录页名称1: "宜心健康客户管理平台（医生端）",
            登录页名称2: "",
            logo: '/common/fqxk.png',
            小程序名称: "番茄心康（患者端）",
        };
    } else if (process.env.VUE_APP_ENV === "test") {
        window.API_ROOT = "https://fqxk-test.i-tcm.cn/";
        document.title = "宜心健康客户管理平台（医生端）";
        dsObj = {
            项目名称: "宜心健康客户管理平台（医生端）",
            登录页名称1: "宜心健康客户管理平台（医生端）",
            登录页名称2: "",
            logo: '/common/fqxk.png',
            小程序名称: "番茄心康（患者端）",
        };
    } else if (process.env.VUE_APP_ENV === "validate") {
        window.API_ROOT = "https://fqxk-validate.i-tcm.cn/";
        document.title = "宜心健康客户管理平台（医生端）";
        dsObj = {
            项目名称: "宜心健康客户管理平台（医生端）",
            登录页名称1: "宜心健康客户管理平台（医生端）",
            登录页名称2: "",
            logo: '/common/fqxk.png',
            小程序名称: "番茄心康（患者端）",
        };
    } else if (process.env.VUE_APP_ENV === "production") {
        window.API_ROOT = "https://fqxk.i-tcm.cn/";
        document.title = "宜心健康客户管理平台（医生端）";
        dsObj = {
            项目名称: "宜心健康客户管理平台（医生端）",
            登录页名称1: "宜心健康客户管理平台（医生端）",
            登录页名称2: "",
            logo: '/common/fqxk.png',
            小程序名称: "番茄心康（患者端）",
        };
    }
    return dsObj
};
export { option, partConfiguration };