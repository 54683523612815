import { RouteConfig } from 'vue-router';

const Qrcode = () => import('./views/qrcode/qrcode.vue');
const Account = () => import('./views/account/account.vue');
const About = () => import('./views/about/about.vue');


const routers: RouteConfig[] = [
    {
        path: 'qrcode',
        component: Qrcode,
    },
    {
        path: 'account',
        component: Account,
    },
    {
        path: 'about',
        component: About,
    },
    {
        path: '',
        redirect: 'Qrcode'
    },

]
export default routers;
