import { RouteConfig } from 'vue-router';

const WorkstationSelect = () => import('./views/workstation-select/workstation-select.vue');
const Qianyue = () => import('./views/qianyue/qianyue.vue');
const EditMessage = () => import('./views/edit-message/edit-message.vue');
const AddMessage = () => import('./views/edit-message/add-message.vue');
const PatientFile = () => import('./views/patient-file/patient-file.vue');
const MenzhenEdit = () => import('./views/menzhen-edit/menzhen-edit.vue');
const ZhibiaoDetail = () => import('./views/zhibiao-detail/zhibiao-detail.vue');
const CepingDetail = () => import('./views/ceping-detail/ceping-detail.vue');
const GanyuEdit = () => import('./views/ganyu-edit/ganyu-edit.vue');
const YizhuEdit = () => import('./views/yizhu-edit/yizhu-edit.vue');
const GanyuDetail = () => import('./views/ganyu-detail/ganyu-detail.vue');
const JianchaSelect = () => import('./views/jiancha-select/jiancha-select.vue');
const JianchaDetail = () => import('./views/jiancha-detail/jiancha-detail.vue');
const PatientCepingEdit = () => import('./views/ceping-edit/ceping-edit.vue');
const PatientZhenliaoEdit = () => import('./views/zhenliao-edit/zhenliao-edit.vue');
const PatientJianyanEdit = () => import('./views/jianyan-edit/jianyan-edit.vue');
const PatientJianchaEdit = () => import('./views/jiancha-edit/jiancha-edit.vue');
const PatientPingguEdit = () => import('./views/pinggu-edit/pinggu-edit.vue');
const PatientPingguReport = () => import('./views/pinggu-edit/pinggu-report.vue');
const PatientPingguIntroduce = () => import('./views/pinggu-edit/pinggu-introduce.vue');
const PatientSuifangEdit = () => import('./views/suifang/suifang-edit.vue');

const routers: RouteConfig[] = [
    {
        path: 'qianyue',
        component: Qianyue,
    },
    {
        path: 'patient-file',
        component: PatientFile,
    },
    {
        path: 'add-message',
        component: AddMessage,
    },
    {
        path: 'patient-edit-message',
        component: EditMessage,
    },
    {
        path: 'patient-menzhen-edit',
        component: MenzhenEdit,
    },
    {
        path: 'patient-zhibiao-detail',
        component: ZhibiaoDetail,
    },
    {
        path: 'patient-ganyu-edit',
        component: GanyuEdit,
    },
    {
        path: 'patient-yizhu-edit',
        component: YizhuEdit,
    },
    {
        path: 'patient-ceping-detail',
        component: CepingDetail,
    },
    {
        path: 'patient-ganyu-detail',
        component: GanyuDetail,
    },
    {
        path: 'workstation-select',
        component: WorkstationSelect,
    },
    {
        path: 'patient-jiancha-select',
        component: JianchaSelect,
    },
    {
        path: 'patient-jiancha-detail',
        component: JianchaDetail,
    },
    {
        path: 'patient-ceping-edit',
        component: PatientCepingEdit,
    },
    {
        path: 'patient-zhenliao-edit',
        component: PatientZhenliaoEdit,
    },
    {
        path: 'patient-jianyan-edit',
        component: PatientJianyanEdit,
    },
    {
        path: 'patient-jiancha-edit',
        component: PatientJianchaEdit,
    },
    {
        path: 'patient-pinggu-edit',
        component: PatientPingguEdit,
    },
    {
        path: 'patient-pinggu-report',
        component: PatientPingguReport,
    },
    {
        path: 'patient-pinggu-introduce',
        component: PatientPingguIntroduce,
    },
    {
        path: 'patient-suifang-edit',
        component: PatientSuifangEdit,
    },
    {
        path: '',
        redirect: 'qianyue'
    },

]
export default routers;
