import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    configuration: {},
    token: '',
    user: {},
    patient: '',
    myState: "专属二维码",
    ifCompose: false,// 当前编辑状态
    currentXuqiu: {},
    zhibiaoRange: {},
    resizeTime: '',// 屏幕大小变化
    patientFileFilter: {
      firstTab: '健康画像',//一级导航
      secondTab: '',// 二级导航
      thirdTab: '',// 三级导航,
      isHideLeft: 'false',//是否隐藏左边患者列表
    },
    patientFileFilterRefrshTime: '',
  },
  mutations: {
    updateCurrentXuqiu(state, data) {
      state.currentXuqiu = data;
    },
    updateToken(state, token) {
      state.token = token;
      sessionStorage.setItem('token', token);

    },
    updateUser(state, data) {
      state.user = data;
      sessionStorage.setItem('user', JSON.stringify(data));

    },
    updateConfiguration(state, data) {
      state.configuration = data;
    },
    updatePatient(state, data) {
      state.patient = data;
    },
    updateMyState(state, data) {
      state.myState = data;
    },
    updateIfCompose(state, ifCompose) {
      state.ifCompose = ifCompose;
    },
    updateZhibiaoRange(state, data) {
      state.zhibiaoRange = data;
    },
    updatePatientFileFilter(state, data) {
      state.patientFileFilter = data;
    },
    updatePatientFileFilterRefrshTime(state, data) {
      state.patientFileFilterRefrshTime = data;
    },
    updateResizeTime(state, data) {
      state.resizeTime = data;
    },
  },
});

export default store;
