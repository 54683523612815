import { RouteConfig } from 'vue-router';

const YichangList = () => import('./views/yichang-list/yichang-list.vue');

const routers: RouteConfig[] = [
    {
        path: 'yichang-list',
        component: YichangList,
    },
    {
        path: '',
        redirect: 'YichangList'
    },

]
export default routers;
