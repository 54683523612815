import { RouteConfig } from 'vue-router';

const Folder = () => import('./views/folder.vue');

const routers: RouteConfig[] = [

    {
        path: 'folder',
        component: Folder,
    },
    {
        path: '',
        redirect: 'folder'
    },

]
export default routers;
